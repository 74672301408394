/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:7793ce29-a7ab-4b58-975d-56f943ea95f1",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_FOU4Cg4Xp",
    "aws_user_pools_web_client_id": "46upve3mnfmb3j79m4p71pjv7u",
    "oauth": {}
};


export default awsmobile;
